import {
  ClassType,
  CSSProperties,
  FocusEventHandler,
  InputHTMLAttributes,
  KeyboardEventHandler,
  useState,
  FocusEvent,
  forwardRef,
  MutableRefObject,
  useMemo,
} from 'react';
import {
  components as importedComponents,
  CSSObjectWithLabel,
  Props as ReactSelectProps,
  SingleValueProps,
  ClearIndicatorProps,
  DropdownIndicatorProps,
  NonceProvider,
} from 'react-select';
import { hexToRgbaString, isDark } from '@neo1/core/utils/colors';
import { enumToLabel } from '@neo1/core/utils/strings';
import { getConfigValue } from 'config';
import Icon from 'components/elements/Icon';
import colors from 'styles/colors';
import styles from './SelectBox.module.css';
import FieldWrapper, { FieldWrapperProps } from '../../FieldWrapper';

export type Option = {
  value: string;
  label: string;
  isFixed?: boolean;
};

export type SelectBoxStylePresets = Partial<{
  noBorders: boolean;
}>;

export interface SelectBoxProps<O = Option>
  extends Omit<FieldWrapperProps, 'children'> {
  autoFocus?: boolean;
  className?: string;
  components?: Object;
  defaultOption?: O;
  defaultValue?: any;
  disabled?: boolean;
  getOptionLabel?: (o: O) => string;
  getOptionValue?: (o: O) => string;
  iconName?: string;
  id?: string;
  name?: string;
  inputId?: string;
  isClearable?: boolean;
  isMulti?: boolean;
  isLoading?: boolean;
  isOptionSelected?: ReactSelectProps<O>['isOptionSelected'];
  isOptionDisabled?: ReactSelectProps<O>['isOptionDisabled'];
  sorted?: boolean;
  noMenu?: boolean;
  onBlur?: FocusEventHandler;
  onChange?: Function;
  menuPlacement?: 'top' | 'bottom';
  onFocus?: FocusEventHandler;
  onInput?: Function;
  onKeyDown?: KeyboardEventHandler;
  options?: Array<O>;
  filterOption?: (option: O, rawInput: string) => boolean;
  placeholder?: string;
  sortBy?: string;
  sortInverted?: boolean;
  style?: any;
  styles?: Record<string, Function>;
  theme?: any;
  value?: any;
  stylePresets?: SelectBoxStylePresets;
  valueKey?: string;
  menuPortalTarget?: HTMLElement;
  isSearchable?: boolean;
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
  hideSelectedOptions?: boolean;
  controlShouldRenderValue?: boolean;
  closeMenuOnSelect?: boolean;
  inputValue?: string;
  onInputChange?: (input: string) => void;
  noBorder?: boolean;
  onMenuOpen?: () => void;
  onMenuClose?: () => void;
  selectedOnTop?: boolean;
  testId?: string;
  noOptionsMessage?: string;
  menuIsOpen?: boolean;
}

const ClearIndicator = (props: ClearIndicatorProps) => {
  const {
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...restInnerProps}
      ref={ref}
      style={getStyles('clearIndicator', props) as CSSProperties}
      className={styles.clearIcon}
      data-testid="selectBoxClearBtn"
    >
      <Icon name="close" />
    </div>
  );
};

const DropdownIndicator = (props: DropdownIndicatorProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <importedComponents.DropdownIndicator {...props}>
    <Icon name="down" className={styles.dropDownIcon} />
  </importedComponents.DropdownIndicator>
);

const Menu = (props: any) => {
  const { children } = props;
  return (
    <>
      <div data-testid="selectBoxMenuMask" className={styles.selectMask} />
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <importedComponents.Menu {...props}>{children}</importedComponents.Menu>
    </>
  );
};

const createInputComponent =
  (inputProps: SelectBoxProps['inputProps']) =>
  ({ ...rest }: any) =>
    (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <importedComponents.Input {...rest} {...inputProps} />
    );

/**
 * Will inject in Select component all styles props
 */
export const withTheme = (
  SelectBoxComponent: ClassType<SelectBoxProps, any, any>,
) => {
  const SelectBoxWithTheme = forwardRef(
    (props: any, ref: MutableRefObject<HTMLInputElement>) => {
      const {
        disabled,
        components,
        className,
        noMenu,
        placeholder,
        onFocus,
        onBlur,
        stylePresets,
        menuPortalTarget,
        inputProps,
        styles: stylesProp,
        id,
        name,
        title,
        info,
        errorText,
        successText,
        helperText,
        keepBottomSpace,
        isInvalid,
        isRequired,
        isSuccess,
        noBorder,
        readOnly,
        readOnlyValue: readOnlyValueProp,
        value,
        isMulti,
        options,
        valueKey,
        onChange,
        onInputChange,
        testId,
        menuIsOpen,
      } = props;
      const [focus, setFocus] = useState(false);

      // !important hack to fix bug DEV-7118 (https://neoone.atlassian.net/browse/DEV-7118)
      const [open, setOpen] = useState(false);

      const [isAutoCompleted, setIsAutoCompleted] = useState(false);

      const handleSearchInputChange = (newValue: string) => {
        // check if the search input is autocompleted
        if (newValue && !focus) {
          setIsAutoCompleted(true);
          if (onChange) {
            // select the first option proposed
            const option = options.find(({ label }) => newValue === label);
            onChange(option ? option[valueKey || 'value'] : value);
          }
        }
        if (onInputChange) {
          onInputChange(newValue);
        }
      };
      // !important end hack

      const noMenuOptionsMessage = (): any => null;

      const CustomizedInput = useMemo(
        () =>
          inputProps
            ? createInputComponent(inputProps)
            : importedComponents.Input,
        [JSON.stringify(inputProps)],
      );

      const handleFocus = (event: FocusEvent<Element, Element>) => {
        setFocus(true);
        if (onFocus) {
          onFocus(event);
        }
      };

      const handleBlur = (event: FocusEvent<Element, Element>) => {
        setFocus(false);
        if (onBlur) {
          onBlur(event);
        }
      };

      let readOnlyValue = readOnlyValueProp;
      if (!readOnlyValue) {
        readOnlyValue = isMulti
          ? options
              ?.reduce((result, option) => {
                if (value.includes(option[valueKey || 'value']))
                  return [...result, option.label];
                return result;
              }, [])
              .join(', ')
          : options?.find((option) => option[valueKey || 'value'] === value)
              ?.label;
      }
      return (
        <FieldWrapper
          id={id}
          name={name}
          title={title}
          info={info}
          errorText={errorText}
          successText={successText}
          helperText={helperText}
          keepBottomSpace={keepBottomSpace}
          isInvalid={isInvalid}
          disabled={disabled}
          readOnly={readOnly}
          readOnlyValue={readOnlyValue}
          isRequired={isRequired}
          isSuccess={isSuccess}
          noBorder={noBorder}
          className={className}
          testId={testId}
        >
          <NonceProvider
            nonce={window.currentCspNonce}
            cacheKey="selectboxcache"
          >
            <SelectBoxComponent
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
              ref={ref}
              onChange={onChange}
              components={{
                ClearIndicator,
                DropdownIndicator,
                Menu,
                Input: CustomizedInput,
                ...components,
              }}
              isDisabled={disabled}
              onFocus={handleFocus}
              onBlur={handleBlur}
              noOptionsMessage={noMenu ? noMenuOptionsMessage : undefined}
              styles={{
                ...stylesProp,
                container: (base: CSSObjectWithLabel) => {
                  const newStyles = { ...base };

                  if (stylePresets?.noBorders) {
                    Object.assign(newStyles, {
                      border: 'none',
                      background: 'transparent',
                    });
                  }

                  return newStyles;
                },
                control: (base: CSSObjectWithLabel) => {
                  const newStyles = {
                    ...base,
                    minHeight: 36,
                    border: 'none',
                    background: 'transparent',
                    borderRadius: 'none',
                    boxShadow: 'none',
                    '&:hover, &:focus-within': {
                      border: '0 !important',
                    },
                  };

                  if (stylePresets?.noBorders) {
                    Object.assign(newStyles, {
                      boxShadow: 'none',
                    });
                  }

                  return newStyles;
                },
                indicatorsContainer: (base: CSSObjectWithLabel) => ({
                  ...base,
                  display: noMenu ? 'none' : 'flex',
                }),
                dropdownIndicator: (base: CSSObjectWithLabel) => ({
                  ...base,
                  color: colors.gray500,
                  padding: '8px 12px',
                  '&:hover, &:focus-within': {
                    color: colors.ink600,
                  },
                  cursor: 'pointer',
                }),
                placeholder: (base: CSSObjectWithLabel) => ({
                  ...base,
                  display: placeholder ? undefined : 'none',
                  color: colors.ink400,
                }),
                singleValue: (
                  base: CSSObjectWithLabel,
                  state: SingleValueProps,
                ) => ({
                  ...base,
                  color: state.isDisabled ? colors.ink600 : 'inherit',
                }),
                menuPortal: (base: CSSObjectWithLabel) => ({
                  ...base,
                  zIndex: 8,
                  position: 'fixed',
                }),
              }}
              inputId={id ? `${id}__input` : undefined}
              optionClassName={styles.label}
              theme={(theme: any) => {
                const primaryColor =
                  getConfigValue('branding')?.primaryColor || colors.primaryBg;
                const primary = isDark(primaryColor)
                  ? primaryColor
                  : colors.ink600;
                return {
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary,
                    primary75: hexToRgbaString(primary, 0.75),
                    primary50: hexToRgbaString(primary, 0.5),
                    primary25: hexToRgbaString(primary, 0.25),
                  },
                };
              }}
              menuPlacement="auto"
              menuPortalTarget={menuPortalTarget || document.body}
              // !important hack to fix bug DEV-7118 (https://neoone.atlassian.net/browse/DEV-7118)
              // its needed to change the way how the internal state of react select about "menuIsOpen" handled by a local state
              // because we dont want the menu to be opened when the search input is autocompleted by browsers
              menuIsOpen={!menuIsOpen ? menuIsOpen : open}
              onMenuOpen={() => {
                if (isAutoCompleted) {
                  // when its autocompleted, do not open the menu
                  setIsAutoCompleted(false);
                } else {
                  setOpen(true);
                }
              }}
              onMenuClose={() => {
                setOpen(false);
              }}
              onInputChange={handleSearchInputChange}
              // when the search input is auto completed by browser, the select box component triggers the `onMenuOpen` callback,
              // but at this moment, the `isAutoCompleted` state is still not set as `true` yet, because react state change is async,
              // so the hack here is to force the remount of the whole select box component
              // some how it triggers again the `onMenuOpen` and at this time we have the `isAutoCompleted` as `true`
              // finally the menu open is blocked.
              key={isAutoCompleted.toString()}
              // !important end hack
            />
          </NonceProvider>
        </FieldWrapper>
      );
    },
  );

  return SelectBoxWithTheme as ClassType<SelectBoxProps, any, any>;
};

/**
 * Maps an enum value to a select option
 * @param value
 */
export const mapEnumToOption = (value: string) => ({
  label: enumToLabel(value),
  value,
});
