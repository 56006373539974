import { useState } from 'react';
import ConfirmLeaveDialog from 'components/elements/Dialog/presets/confirmLeave';
import { Props as DialogProps } from 'components/elements/Dialog/types';

const useConfirmDialog = (
  onClose: (values: Record<string, any>) => void,
  isOpen: boolean,
) => {
  const [dialog, setDialog] = useState<Partial<DialogProps>>(null);

  const handleOnClose = (values: Record<string, any>, dirty: boolean) => {
    if (!isOpen || !onClose) {
      return;
    }
    if (dirty) {
      setDialog({
        onConfirm: () => onClose(values),
      });
    } else {
      onClose(values);
    }
  };

  const confirmDialog = dialog ? (
    <ConfirmLeaveDialog
      onConfirm={dialog.onConfirm}
      onClose={() => setDialog(null)}
    />
  ) : null;

  return {
    confirmDialog,
    handleOnClose,
  };
};

export default useConfirmDialog;
