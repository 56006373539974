import { UUID } from '@neo1/client';

export enum Action {
  SET_GROUPS = 'one/recipient/SET_GROUPS',
}
type SetGroups = {
  type: Action.SET_GROUPS;
  groupsIds: UUID[];
};

export function setGroups(groupsIds: UUID[]): SetGroups {
  return { type: Action.SET_GROUPS, groupsIds };
}

export type GroupAction = SetGroups;
