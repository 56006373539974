import { UUID } from '@neo1/client';

export enum Action {
  SET_RECIPIENTS = 'one/recipient/SET_RECIPIENTS',
  SET_RECIPIENT_COMPANIES = 'one/recipient/SET_RECIPIENT_COMPANIES',
}
type SetRecipients = {
  type: Action.SET_RECIPIENTS;
  recipientsIds: UUID[];
};
type SetRecipientCompanies = {
  type: Action.SET_RECIPIENT_COMPANIES;
  recipientCompaniesIds: UUID[];
};

export function setRecipients(recipientsIds: UUID[]): SetRecipients {
  return { type: Action.SET_RECIPIENTS, recipientsIds };
}
export function setRecipientCompanies(
  recipientCompaniesIds: UUID[],
): SetRecipientCompanies {
  return { type: Action.SET_RECIPIENT_COMPANIES, recipientCompaniesIds };
}

export type RecipientAction = SetRecipients | SetRecipientCompanies;
