import { AnyAction } from 'redux';
import { combineEpics } from 'redux-observable';
import { AppCoreState } from 'redux/types';

import { loadFile } from 'modules/App/redux/epic';
import reports from 'modules/Reports/redux/epic';
import { fetchSpendCounts } from 'redux/counter/epic';
import resetDashboaardScope from 'modules/Dashboard/redux/epic';

export default combineEpics<AnyAction, AnyAction, AppCoreState, any>(
  fetchSpendCounts,
  loadFile,
  reports,
  resetDashboaardScope,
);
