import { UUID } from '@neo1/client';
import { Action, RecipientAction } from './actions';

export type RecipientState = {
  recipientsIds: UUID[];
  recipientCompaniesIds: UUID[];
};
const initialState: RecipientState = {
  recipientsIds: [],
  recipientCompaniesIds: [],
};

export default (
  state: RecipientState = initialState,
  action: RecipientAction = null,
): RecipientState => {
  switch (action?.type) {
    case Action.SET_RECIPIENTS:
      return { ...state, recipientsIds: action.recipientsIds };
    case Action.SET_RECIPIENT_COMPANIES:
      return { ...state, recipientCompaniesIds: action.recipientCompaniesIds };
    default:
      return state;
  }
};
