import { combineReducers, createStore, Reducer } from 'redux';
import accountingIntegration from 'redux/accountingIntegrations/reducer';
import amazonConfig from 'redux/amazonConfig/reducer';
import generalConfig from 'redux/generalConfig/reducer';
import app from 'modules/App/redux/reducer';
import admin from 'modules/Admin/redux/reducer';
import auth from 'modules/Authentification/redux/reducer';
import counter from 'redux/counter/reducer';
import dashboard from 'modules/Dashboard/redux/reducer';
import authorize from 'modules/Authorize/redux/reducer';
import spend from 'modules/Spend/redux/reducer';
import budget from 'modules/Budgets/redux/reducer';
import supply from 'modules/Supplies/redux/reducer';
import profile from 'modules/Profile/redux/reducer';
import reporting from 'modules/Reports/redux/reducer';
import reconciliation from 'modules/Reconciliation/redux/reducer';
import receipt from 'modules/Receipts/redux/reducer';
import settings from 'modules/Settings/redux/reducer';
import { AuthActions } from 'modules/Authentification/redux/actions';
import { AppCoreState } from 'redux/types';
import entities from 'redux/entities/reducer';
import recipient from 'redux/recipient/reducer';
import group from 'redux/group/reducer';
import travel from 'modules/Travel/redux/reducer';
import { RootActions } from './actions';
import fakeState from './fakeState';

const appReducers = combineReducers<AppCoreState>({
  app,
  accountingIntegration,
  amazonConfig,
  generalConfig,
  admin,
  auth,
  counter,
  dashboard,
  profile,
  group,
  authorize,
  budget,
  supply,
  spend,
  reporting,
  receipt,
  reconciliation,
  recipient,
  settings,
  travel,
  entities,
});

const { getState } = createStore(appReducers, undefined);
const initialState = getState();

const rootReducer: Reducer<AppCoreState> = (state, action) => {
  let newState: AppCoreState = {
    ...state,
  };

  // !important
  // always keep the app.referential state during the life cycle of the application
  // because its data feching mechanism is handled by a sigleton hook `useAppReferentialSingleton`
  // which is only called once during the life cycle of the app
  const initState = {
    ...initialState,
    app: {
      ...initialState.app,
      referential: state?.app?.referential,
    },
  };

  switch (action.type) {
    case AuthActions.LOGOUT_AS:
      newState = {
        ...initState,
        auth: state.auth,
      };
      break;
    case AuthActions.LOGOUT:
    case RootActions.CLEAR_FAKE_STATE:
      newState = initState;
      break;
    case RootActions.SET_FAKE_STATE:
      newState = {
        ...initState,
        ...fakeState,
      };
      break;
    default:
  }

  return appReducers(newState, action);
};

export default rootReducer;
