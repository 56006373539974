import { ReactNode } from 'react';
import isFunction from 'lodash/isFunction';
import { FormikProps, useFormikContext } from 'formik';
import Spinner from 'components/elements/Loader/Spinner';
import Modal from './Modal';
import { Presenter } from './types';
import styles from './ModalFormik.module.css';

interface Props extends Omit<Presenter, 'children'> {
  children: ReactNode | ((formikProps: FormikProps<any>) => ReactNode);
  isLoading?: boolean;
  testId?: string;
  onDismissInfoBox?: () => void;
}

const ModalFormik = ({
  children: mayBeFunction,
  id,
  className,
  onClose,
  title,
  contentClassName,
  footer,
  showHeader,
  isOpen,
  error,
  wrapperComponent,
  wrapperProps,
  formClassName,
  isReadOnly,
  noScroll,
  showErrors,
  dirty,
  onSubmit,
  submitting,
  noAutoFocus,
  showButtons,
  headerClassName,
  isLoading,
  testId,
  onDismissInfoBox,
}: Props) => {
  const formikContext = useFormikContext<any>();
  const children = isFunction(mayBeFunction)
    ? mayBeFunction(formikContext)
    : mayBeFunction;
  return (
    <Modal
      id={id}
      className={className}
      onClose={onClose}
      title={title}
      contentClassName={contentClassName}
      footer={footer}
      showHeader={showHeader}
      isOpen={isOpen}
      error={error}
      wrapperComponent={wrapperComponent}
      wrapperProps={wrapperProps}
      formClassName={formClassName}
      isReadOnly={isReadOnly}
      noScroll={noScroll}
      showErrors={showErrors}
      dirty={dirty}
      onSubmit={onSubmit}
      submitting={submitting}
      noAutoFocus={noAutoFocus}
      showButtons={showButtons}
      headerClassName={headerClassName}
      testId={testId}
      onDismissInfoBox={onDismissInfoBox}
    >
      {isLoading ? (
        <div className={styles.spinnerWrapper}>
          <Spinner />
        </div>
      ) : (
        children
      )}
    </Modal>
  );
};

ModalFormik.defaultProps = {
  isLoading: false,
  testId: undefined,
  onDismissInfoBox: undefined,
};

export default ModalFormik;
