import { useSelector } from 'react-redux';
import { UUID } from '@neo1/client';
import { selectEffectiveUser } from 'modules/Authentification/redux/selectors';
import useLogAs from 'modules/Authentification/useLogAs';
import useLogoutAs from 'modules/Authentification/useLogoutAs';
import useLoggableAsUsers from 'modules/Authentification/useLoggableAsUsers';
import SelectBox from 'components/elements/form/fields/SelectBox';
import Link from 'components/elements/Link';
import styles from './LoggedInAsBanner.module.css';

const LoggedInAsBanner = () => {
  const effectiveUser = useSelector(selectEffectiveUser);
  const loggableUsers = useLoggableAsUsers();
  const { execute: logAs } = useLogAs();
  const { execute: logoutAs } = useLogoutAs();

  const onUserChange = (userId: UUID) => {
    const user = loggableUsers.find((u) => u.id === userId);
    logAs(user.id, user.email);
  };

  return (
    <div
      className={styles.container}
      data-testid="loggedInAsBanner"
      role="banner"
      aria-label="login status"
    >
      <div className={styles.content}>
        You are logged in as <strong>{effectiveUser.displayName}</strong>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link to="#" standAlone bold onClick={() => logoutAs()}>
          Quit log as
        </Link>
      </div>
      {loggableUsers.length > 0 && (
        <>
          <hr className={styles.divider} />
          <SelectBox
            id="selectAnotherLogAsUser"
            options={loggableUsers.map((user) => ({
              value: user.id,
              label: user.displayName,
            }))}
            onChange={onUserChange}
            placeholder="Log as another user"
            className={styles.input}
          />
        </>
      )}
    </div>
  );
};

export default LoggedInAsBanner;
