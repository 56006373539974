import { UUID } from '@neo1/client';
import { Action, GroupAction } from './actions';

export type GroupState = {
  groupsIds: UUID[];
};
const initialState: GroupState = {
  groupsIds: [],
};

export default (
  state: GroupState = initialState,
  action: GroupAction = null,
): GroupState => {
  switch (action?.type) {
    case Action.SET_GROUPS:
      return { ...state, groupsIds: action.groupsIds };

    default:
      return state;
  }
};
