import { ofType } from 'redux-observable';
import { AnyAction } from 'redux';
import { AuthActions } from 'modules/Authentification/redux/actions';
import { map } from 'rxjs/operators';
import ScopeType from 'redux/scopeType';
import { changeScope } from './actions';

const resetDashboaardScope = (action$) =>
  action$.pipe(
    ofType<AnyAction, AuthActions>(
      AuthActions.LOGOUT,
      AuthActions.LOGIN_AS,
      AuthActions.LOGOUT_AS,
    ),
    map(() => changeScope(ScopeType.User)),
  );
export default resetDashboaardScope;
