import { useEffect, useState, useRef } from 'react';
import classNames from 'classnames';
import PrismaZoom from 'react-prismazoom';
import config from 'config';

import { fetch } from '@neo1/core/utils/files';
import Button, { ButtonTheme } from 'components/elements/Button';
import LoaderImage from 'components/elements/Loader/Image';
import styles from './Image.module.css';

export const normalizeImgSrc = (path: string) =>
  path && path.match(/^https?:\/\//)
    ? path
    : `${config.imagesContextPath}/${path}`;

const ZOOM_STEP = 0.2;

const ZOOM_MIN = 1;

const ZOOM_MAX = 5;

export type Props = {
  path: string;
  alt?: string;
  role?: string;
  width?: number;
  height?: number;
  className?: string;
  title?: string;
  fromFileService?: boolean;
  advanced?: boolean;
};

const Image = ({
  path,
  alt,
  role,
  width,
  height,
  className,
  title,
  fromFileService,
  advanced,
}: Props) => {
  const zoomRef = useRef(null);

  const [zoom, setZoom] = useState(ZOOM_MIN);

  const [loading, setLoading] = useState(true);

  const [src, setSrc] = useState('');

  const loadImageFromBundle = () => {
    setSrc(normalizeImgSrc(path));
    setLoading(false);
  };

  const loadImageFromAws = async () => {
    try {
      const dataUrl = await fetch(normalizeImgSrc(path));
      setSrc(dataUrl);
    } catch (err) {
      setSrc('');
    }
    setLoading(false);
  };

  const load = () => {
    setLoading(true);
    if (fromFileService) {
      loadImageFromAws();
    } else {
      loadImageFromBundle();
    }
  };

  useEffect(load, [fromFileService, path]);

  if (loading) return <LoaderImage width={20} height={20} />;

  const onZoomOut = () => zoomRef.current?.zoomOut(ZOOM_STEP);

  const onZoomIn = () => zoomRef.current?.zoomIn(ZOOM_STEP);

  if (!advanced) {
    return (
      <img
        src={src}
        alt={alt}
        role={role}
        width={width}
        height={height}
        className={classNames(styles.img, className)}
        title={title}
      />
    );
  }

  const error = 'Failed to load image.';

  const image = (
    <div
      style={{ backgroundImage: `url(${src})` }}
      className={styles.advancedImage}
    />
  );

  return (
    <div className={styles.advancedWrapper}>
      <div className={styles.actions}>
        <Button
          label="Zoom out"
          iconName="minusCircle"
          iconOnly
          id="zoomOutBtn"
          onClick={onZoomOut}
          disabled={zoom <= ZOOM_MIN}
          theme={ButtonTheme.Ghost}
        />
        <span>{`${((zoom - ZOOM_MIN + 1) * 100).toFixed(0)}%`}</span>
        <Button
          label="Zoom in"
          iconName="plusCircle"
          iconOnly
          id="zoomInBtn"
          onClick={onZoomIn}
          disabled={zoom >= ZOOM_MAX}
          theme={ButtonTheme.Ghost}
        />
      </div>
      <div className={styles.zoomContainer}>
        <PrismaZoom
          ref={zoomRef}
          minZoom={ZOOM_MIN}
          maxZoom={ZOOM_MAX}
          onZoomChange={setZoom}
          className={styles.zoom}
        >
          {!loading && !src ? error : image}
        </PrismaZoom>
      </div>
    </div>
  );
};

Image.defaultProps = {
  alt: 'Image',
  role: 'presentation',
  width: undefined,
  height: undefined,
  className: undefined,
  title: undefined,
  fromFileService: false,
  advanced: false,
};

export default Image;
