import { useHistory } from 'react-router';
import classNames from 'classnames';
import { ReactComponent as Neo1Logo } from '../../../../../public/images/logos/neo1White.svg';
import styles from './styles.module.css';

type Props = {
  size?: 'md' | 'sm';
};

const Logo = ({ size }: Props) => {
  const history = useHistory();

  const handleClick = () => {
    history.push('/');
  };

  return (
    <button
      type="button"
      aria-label="Neo1Logo"
      className={styles.container}
      onClick={handleClick}
      tabIndex={0}
    >
      <Neo1Logo className={classNames(styles.logo, styles[size])} />
    </button>
  );
};

Logo.defaultProps = {
  size: 'md',
};

export default Logo;
