import { useRef, useEffect, useState, KeyboardEvent } from 'react';
import FocusTrap from 'focus-trap-react';
import Portal from 'components/composed/Portal';
import Button, { ButtonTheme, ButtonSize } from 'components/elements/Button';
import { Props } from './types';
import styles from './styles.module.css';

const Dialog = ({
  title,
  children,
  confirmText,
  cancelText,
  confirmLabel,
  onConfirm,
  onClose,
  onCancel,
  dataTestId,
}: Props) => {
  const ref = useRef<HTMLDivElement>();

  const [busy, setBusy] = useState(false);

  const handleConfirm = async (e) => {
    e.stopPropagation();
    setBusy(true);
    await onConfirm();
    onClose();
  };

  const handleCancel = (e) => {
    e.stopPropagation();
    if (!busy) {
      if (onCancel) onCancel();
      onClose();
    }
  };

  const handleOutsideClick = (e) => {
    handleCancel(e);
  };

  const handleKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleConfirm(e);
    } else if (e.key === 'Escape') {
      handleCancel(e);
    }
  };

  useEffect(() => {
    if (!ref.current) return;
    ref?.current?.focus();
  }, []);

  return (
    <Portal>
      <section className={styles.container} data-testid={dataTestId}>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions,jsx-a11y/no-static-element-interactions */}
        <div
          ref={ref}
          onClick={handleOutsideClick}
          className={styles.overlay}
          onKeyDown={handleKeyPress}
        />
        <FocusTrap
          focusTrapOptions={{
            clickOutsideDeactivates: true,
            fallbackFocus: `div[role='dialog']`,
          }}
        >
          <div role="dialog" className={styles.box}>
            <p className={styles.title}>{title}</p>
            <div className={styles.content}>{children}</div>
            {confirmText ? (
              <Button
                label={confirmLabel ?? confirmText}
                onClick={handleConfirm}
                wrapperClassName={styles.confirmButton}
                size={ButtonSize.Large}
                testId="confirmBtn"
                loading={busy}
              />
            ) : null}
            {cancelText ? (
              <Button
                label={cancelText}
                onClick={handleCancel}
                wrapperClassName={styles.cancelButton}
                theme={ButtonTheme.Ghost}
                size={ButtonSize.Large}
                testId="cancelBtn"
                disabled={busy}
              />
            ) : null}
          </div>
        </FocusTrap>
      </section>
    </Portal>
  );
};

Dialog.defaultProps = {
  confirmText: 'Confirm',
  confirmLabel: undefined,
  cancelText: 'Cancel',
  dataTestId: 'dialog',
  onCancel: undefined,
};

export default Dialog;
